import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

import { useApi } from '@/composables/useApi';

import useEditorStore from '@/stores/editor';
// import { usePeachy } from '@/composables/usePeachy';

export function generateKeyForChange({ route = undefined, key, subkey = undefined }) {
	return [route, key, subkey].filter(Boolean).join('_');
}

export default defineStore('editorChanges', () => {
	const VueRoute = useRoute();
	/** @type {{ type: string, key: string, value: string, status: string, subkey: string }{}} */

	// const peachy = usePeachy();
	const schemas = ref({});

	const savingChanges = ref(false);
	const saveDomain = ref(null);
	const saveRoute = ref(null);
	const saveStatus = ref('hide');
	const saveError = ref(null);

	const localChanges = ref({});

	const hasLocalChanges = computed(() => {
		return Object.keys(localChanges.value).length > 0;
	});

	function addChange({
		route = undefined,
		type,
		value,
		status = 'approved',
		key,
		subkey = undefined,
		allowed_terms = [],
	}) {
		const index = generateKeyForChange({ route, key, subkey });
		const temp = {
			type,
			value,
			status,
			key,
			subkey,
			route,
			allowed_terms,
			editorChangeKey: index,
		};
		localChanges.value[index] = temp;
	}
	function removeChange({ key, route = undefined, subkey = undefined }) {
		const index = generateKeyForChange({ route, key, subkey });
		if (localChanges.value[index]) {
			delete localChanges.value[index];
		}
	}

	async function uploadImage(domainName, overrideType, imageBlob) {
		const form = new FormData();
		form.append('domain_name', domainName);
		form.append('image_type', overrideType);
		form.append('image_ext', 'jpg');
		form.append('file', imageBlob);

		const { data } = await useApi(`image_upload/`, {
			v3Agent: true,
			agentID: VueRoute.params.agentID,
			message: 'There was a problem uploading that image.',
		})
			.post(form)
			.json();
		return data.value?.fileUrl;
	}

	async function saveImageOverride({ value, type, status, key, subkey, route, allowed_terms }) {
		if (!value) {
			throw new Error('blob is not defined');
		}
		if (typeof value !== 'string') {
			// e.g. banner images is not a preapproved image; need to upload and get a URL
			value = await uploadImage(VueRoute.params.domainName, type, value);
		}
		await saveContentOverride({
			type,
			value,
			status,
			key,
			subkey,
			route,
			allowed_terms,
		});
	}
	async function saveContentOverride(data) {
		// peachy('contentoverride', { path: null, payload: { ...data } });

		const editorStore = useEditorStore();
		const branch = editorStore.core.value?.meta?.branch ?? 'master';
		const version = editorStore.core.value?.meta?.version ?? '';

		try {
			await useApi(`api/v3/domains/${VueRoute.params.domainName}/overrides/`, {
				message: 'There was an issue saving that override.',
			}).post({
				...data,
				override_type: data.type,
				branch,
				version,
				time: new Date().getTime(),
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function putRouteChanges(routes = []) {
		// TODO: does this change constitute a content override event we need to track?
		// peachy('contentoverride', { path: null, payload: { ...data } });

		const editorStore = useEditorStore();

		try {
			await useApi(`api/v3/domains/${VueRoute.params.domainName}/routes/`, {
				headers: { 'content-type': 'application/json' },
				message: 'There was an issue changing that route.',
			}).put(JSON.stringify(routes));
			await editorStore.updateRoutes();
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function updateRouteContent(routes = []) {
		// TODO: does this change constitute a content override event we need to track?
		// peachy('contentoverride', { path: null, payload: { ...data } });
		const editorStore = useEditorStore();
		try {
			const { error } = await useApi(
				`api/v3/domains/${VueRoute.params.domainName}/routes/update_content/`,
				{
					message: 'There was an issue updating the route content.',
					headers: { 'content-type': 'application/json' },
				}
			).post(JSON.stringify(routes));
			if (error?.value) {
				throw error.value;
			}

			await Promise.all([editorStore.updateRoutes(), editorStore.updateConfig()]);
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function saveArticleBlacklist(article_blacklist) {
		try {
			if (!article_blacklist) {
				throw new ReferenceError('Article blacklist is not defined');
			}
			const editorStore = useEditorStore();

			await useApi(`api/v3/domains/${VueRoute.params.domainName}/article_blacklist/`, {
				message: `The new list of hidden articles for ${VueRoute.params.domainName} did not save properly`,
			}).put({
				article_blacklist,
			});
			await editorStore.getArticleBlacklist();
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	}
	async function getPromiseByType({
		type = null,
		value = null,
		status = 'approved',
		key,
		subkey,
		route = undefined,
		allowed_terms = [],
	}) {
		/**
		 * convert list of changes to promises
		 * @param {string} type - The type of change - used to compute endpoint.
		 * @param {string} key Content override type
		 * @param {string} route Route that this override applies to
		 * @param {string} value - The actual content to be saved.
		 * @param {string} status [approved] Can be 'pending'
		 * @param {string} subkey Used for specific instances within content override type, e.g. ${team member id}.description
		 */
		if (value !== undefined && value !== null) {
			try {
				switch (type) {
					case 'articles_blacklist':
						return saveArticleBlacklist(value);

					case 'agent_avatar':
					case 'office_banner':
					case 'staff_avatar':
					case 'jobs_team_images':
					case 'mission_tab_images':
					case 'team_tab_images':
					case 'locations_image':
						return saveImageOverride({
							type,
							value,
							status,
							key,
							subkey,
							route,
							allowed_terms,
						});

					case 'agent_about_me':
					case 'jobs-tab-enable':
					case 'meta_description':
					case 'mission':
					case 'meta_page_title':
					case 'office_timezone':
					case 'office_structured_hours':
					case 'social_feeds':
					case 'team_tab_display':
					case 'team_description':
					case 'team_member_order':
					case 'team_member':
					default:
						return saveContentOverride({
							type,
							value,
							status,
							key,
							subkey,
							route,
							allowed_terms,
						});
				}
			} catch (error) {
				return Promise.reject(error);
			}
		} else {
			return Promise.reject(new ReferenceError(`Value is not defined for key: ${key}`));
		}
	}
	async function deleteContentOverride({ key }) {
		await useApi(`api/v3/domains/${VueRoute.params.domainName}/overrides/`, {
			message: 'There was an issue deleting that override.',
		}).delete({ key });
	}
	async function saveSingleChange({ route, key, subkey }) {
		/*
		 * WARNING: this does not reload editor data by design.
		 * Components that use this function (e.g. AgentProfileImage)
		 * will need to re-initialize editor data after calling.value
		 */
		saveStatus.value = 'saving';
		savingChanges.value = true;
		try {
			const targetKey = generateKeyForChange({ route, key, subkey });
			const target = localChanges.value[targetKey];

			if (!target) {
				throw new ReferenceError(`Targeted change is not defined for ${targetKey}`);
			}
			await getPromiseByType(target);
			removeChange({ key, route, subkey });
			saveStatus.value = 'saved';
		} catch (error) {
			saveStatus.value = 'error';

			return Promise.reject(error);
		} finally {
			setTimeout(() => {
				saveStatus.value = 'hide';
				savingChanges.value = false;
			}, 6000);
		}
	}
	async function saveAllChanges() {
		saveStatus.value = 'saving';
		const editorStore = useEditorStore();
		savingChanges.value = true;
		saveError.value = null;
		try {
			const promises = Object.values(localChanges.value).map(await getPromiseByType);
			await Promise.all(promises);
			saveStatus.value = 'saved';
			await this.$reset();
			await editorStore.initializeEditor();
		} catch (error) {
			saveError.value = error;
			saveStatus.value = 'error';
			return Promise.reject(error);
		} finally {
			setTimeout(async () => {
				saveStatus.value = 'hide';
				saveError.value = null;
			}, 6000);
			savingChanges.value = false;
		}
	}

	async function controlSaveDialog({
		status = 'hide',
		domain = null,
		route = null,
		error = null,
	}) {
		/*
		 this only controls the display of the saveDialog component.
		 valid status states:
		 ['saving','saved','error','hide']
		 */
		saveStatus.value = status;
		saveDomain.value = domain;
		saveRoute.value = route;
		saveError.value = error;

		if (status !== 'saving') {
			setTimeout(() => {
				saveStatus.value = 'hide';
				saveDomain.value = null;
				saveRoute.value = null;
				saveError.value = null;
			}, 6000);
		}

		if (error) {
			console.error(error);
		}
	}

	return {
		schemas,

		savingChanges,
		saveDomain,
		saveRoute,
		saveStatus,
		saveError,

		localChanges,

		hasLocalChanges,

		addChange,
		removeChange,
		deleteContentOverride,
		saveSingleChange,
		saveAllChanges,
		saveArticleBlacklist,

		saveImageOverride,
		saveContentOverride,
		putRouteChanges,
		controlSaveDialog,
		updateRouteContent,

		//testing
		getPromiseByType,
		uploadImage,
	};
});
