import { ref, watchEffect } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { useApi } from '@/composables/useApi';

export default defineStore('agent/emails', () => {
	const VueRoute = useRoute();

	const emails = ref(null);
	const fetchingEmails = ref(false);
	const processingEmails = ref([]);
	const emailLoadProgress = ref(0);
	function resetEmails() {
		emails.value = null;
	}
	async function getEmails() {
		fetchingEmails.value = true;
		try {
			const fetcher = useApi(`emails/`, {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message: "We couldn't retrieve your emails! Please try again later",
			});

			watchEffect(() => {
				emailLoadProgress.value = fetcher.progress;
			});
			const result = await fetcher.json();
			fetchingEmails.value = false;

			emails.value = result.data?.value ?? [];
		} catch {
			return Promise.reject();
		}
	}
	async function ensureEmails() {
		if (!(emails.value || fetchingEmails.value)) {
			await getEmails();
		} else {
			return Promise.resolve();
		}
	}
	async function addNewEmail({ domain_name, redirect_address, real_address }) {
		try {
			processingEmails.value = [{ domain_name, redirect_address, real_address }];

			await useApi(`domains/${domain_name}/emails/`, {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message: `There was an issue with adding that ${redirect_address} as a new redirect. Please try again later.`,
			}).post({ redirect_address, real_address });
		} catch (error) {
			console.error(error);
		} finally {
			await getEmails();
			processingEmails.value = [];
		}
	}
	async function saveEmailEdits({ domain, redirect, redirect_address, real_address }) {
		try {
			await useApi(`domains/${domain}/emails/${redirect}/`, {
				message: 'There was an issue saving your email redirect! Please try again later.',
				agent: true,
				agentID: VueRoute.params.agentID,
			}).put({
				redirect_address,
				real_address,
			});
		} catch (error) {
			console.error(error);
		} finally {
			await getEmails();
		}
	}

	async function testEmail({ destination }) {
		const response = await useApi(`emails/test_email/${destination}/`, {
			agent: true,
			agentID: VueRoute.params.agentID,
			message: 'There was an issue testing that email. Please try again later.',
		})
			.post()
			.json();
		if (response.data) {
			return response.data;
		}
	}

	async function deleteEmail({ domain, redirect }) {
		try {
			await useApi(`domains/${domain}/emails/${redirect}/`, {
				agent: true,
				agentID: VueRoute.params.agentID,
				message: 'There was an issue deleting that email. Please try again',
			}).delete();
		} catch (error) {
			console.error(error);
		} finally {
			await getEmails();
		}
	}

	return {
		emails,
		processingEmails,
		fetchingEmails,
		emailLoadProgress,

		getEmails,
		ensureEmails,
		saveEmailEdits,
		testEmail,
		deleteEmail,
		addNewEmail,
		resetEmails,
	};
});
