import { defineRule } from 'vee-validate';
import { all } from '@vee-validate/rules';

import { number, expirationYear, expirationMonth, expirationDate } from 'card-validator';

export function setupValidation() {
	defineRule('card_expiration_month', (month, { year }) => {
		const { isValid: monthIsValid } = expirationMonth(month);
		const { isValid: dateIsValid } = expirationDate({ month, year });

		if (year === null || year.length !== 4) {
			return true;
		} else if (dateIsValid && monthIsValid) {
			return true;
		} else if (monthIsValid) {
			return `This month/year combo is not valid`;
		}
		return `Not a valid month`;
	});
	defineRule('card_expiration_year', (year, { month }) => {
		const yearInfo = expirationYear(year);
		const dateInfo = expirationDate({ month, year });
		const { isValid: yearIsValid, isPotentiallyValid } = yearInfo;
		const { isValid: dateIsValid } = dateInfo;

		if (year === null || year === undefined || year.length !== 4) {
			return `${year} must be 4 digits long.`;
		} else if (dateIsValid && yearIsValid) {
			return true;
		} else if (isPotentiallyValid) {
			return true;
		}
		return `Not a valid year, is it in the past?`;
	});
	defineRule('card_security_code', (value, { number: card_number, newCard }) => {
		if (!value && !card_number && !newCard) {
			return true;
		}

		const cardInfo = number(card_number);
		const { card } = cardInfo;

		if (!value) {
			return 'must provide cvv';
		}
		if (!card) {
			return 'must provide card number to validate cvv.';
		}

		if (card.code.size === value.length) {
			return true;
		} else {
			return `${card.code.name} should be ${card.code.size} digits.`;
		}
	});

	defineRule('credit_card', value => {
		const { card, isPotentiallyValid, isValid } = number(value);

		if (isValid) {
			return true;
		} else {
			if (!Number.isInteger(value)) {
				return `Card number should be numeric.`;
			}
			if (isPotentiallyValid) {
				if (card) {
					if (!card.lengths.includes(value.length)) {
						return `${card.niceType} cards should be ${new Intl.ListFormat('en', {
							style: 'long',
							type: 'disjunction',
						}).format(card.lengths.map(l => l.toString()))} digits.`;
					}
				}
			} else {
				return '${field} does not appear to be a valid credit card number.';
			}
		}
	});

	[
		'between',
		'digits',
		'email',
		'image',
		'integer',
		'max',
		'min',
		'numeric',
		'regex',
		'required',
		'size',
	].forEach(rule => {
		defineRule(rule, all[rule]);
	});

	defineRule('dedupe', (item, { list = [] }, { field }) => {
		if (list.includes(item.trim())) {
			return `${field} should be unique. It is already included in the list.`;
		}
		return true;
	});

	defineRule('url', value => {
		try {
			new URL(value);
			return true;
		} catch {
			return `Please enter a valid url`;
		}
	});
}
