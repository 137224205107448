import { ref } from 'vue';
import { defineStore } from 'pinia';
import { formatISO, isDate } from 'date-fns';

import { useApi } from '@/composables/useApi';

import useAlertStore from '@/stores/alerts';
import { useRoute } from 'vue-router';

export default defineStore('agent/calltracking', () => {
	const VueRoute = useRoute();

	const callRes = ref({});
	const stats = ref(null);
	const account = ref(null);
	const callList = ref([]);
	const callListCount = ref(null);
	const loadingStats = ref(false);

	async function getAccount() {
		account.value = null;

		const { statusCode, data } = await useApi(`calltracking/`, {
			v3Agent: true,
			agentID: VueRoute.params.agentID,
			message:
				'There was an issue with retrieving your calltracking info! Please try again later.',
			alertOnFailure: false,
		}).json();

		if (statusCode.value === 404) {
			account.value = false;
		} else {
			account.value = data.value;
		}
	}

	async function update(changes) {
		try {
			await useApi('calltracking/', {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue with saving your calltracking changes. Please try again later.',
			}).put(changes);
			await getAccount();
			return Promise.resolve();
		} catch (error) {
			console.error(error);
			return Promise.reject();
		}
	}

	async function getCallRes(start, end) {
		if (!isDate(start) || !isDate(end)) {
			return Promise.reject('Valid start and end dates required');
		}

		const result = await useApi(
			`calltracking/calls/?${new URLSearchParams({
				start: formatISO(start),
				end: formatISO(end),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message: 'There was an issue retrieving your qlp calls! Please try again later. ',
			}
		).json();
		callRes.value = result?.data?.value;

		return Promise.resolve();
	}

	async function createAccount() {
		try {
			await useApi(`calltracking/`, {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message: `There was an issue creating your calltracking account! Please try again later.`,
			}).post();
			await getAccount();
		} catch (error) {
			const alertStore = useAlertStore();
			alertStore.addAlert({ message: error });
		}
	}

	async function disable() {
		await useApi(`calltracking/`, {
			v3Agent: true,
			agentID: VueRoute.params.agentID,
			message:
				'There was an issue disabling your calltracking account! Please try again later.',
		}).delete();
		await getAccount();
	}

	async function setAreaCode(code) {
		try {
			const result = await useApi('calltracking/retry_session_tracker/', {
				agent: true,
				agentID: VueRoute.params.agentID,
				message: `There was an issue setting your calltracking area code! Please try again in a few minutes.`,
			})
				.post({ code })
				.json();

			if (result?.data?.value?.success === false) {
				const alertStore = useAlertStore();

				alertStore.addAlert({
					message: `No phone numbers available for area code ${code}`,
					type: 'info',
				});
			} else {
				await getAccount();
			}
		} catch (error) {
			console.error(error);
		}
	}

	async function getSortedCallList({
		start,
		end,
		sort = `start_time`,
		sort_dir = `desc`,
		page = 0,
	}) {
		if (!isDate(start) || !isDate(end)) {
			return Promise.reject('Valid start and end dates required');
		}

		callList.value = [];
		callListCount.value = null;
		const result = await useApi(
			`calltracking/calls/?${new URLSearchParams({
				start: formatISO(start),
				end: formatISO(end),
				sort,
				sort_dir,
				page,
				limit: 9999,
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message: 'There was an issue getting your call history! Please try again later.',
			}
		).json();

		const data = result.data?.value ?? {};
		callList.value = data.calls;
		callListCount.value = data.call_count;
	}

	async function getStats(start, end) {
		if (!isDate(start) || !isDate(end)) {
			return Promise.reject('Valid start and end dates required');
		}
		loadingStats.value = true;

		const result = await useApi(
			`calltracking/stats/?${new URLSearchParams({
				start: formatISO(start),
				end: formatISO(end),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your calltracking data! Please try again later.',
			}
		).json();

		const data = result?.data?.value ?? {};
		stats.value = data.stats;
		loadingStats.value = false;
	}

	return {
		callRes,
		stats,
		account,
		callList,
		callListCount,

		getAccount,
		update,
		getCallRes,
		createAccount,
		disable,
		setAreaCode,
		getSortedCallList,
		getStats,
		loadingStats,
	};
});
