import Keen from 'keen-tracking';

function readMetaTags() {
	const mt = {};
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const metas = document.getElementsByTagName('meta');
		for (let i = 0; i < metas.length; i++) {
			const name = metas[i].getAttribute('name');
			if (name) {
				mt[name] = metas[i].getAttribute('content');
			}
		}
	}
	return mt;
}

function extender() {
	const meta = readMetaTags();
	return {
		__passthrough: meta.peachy || null,
		backend: 'mysfdomain',
		product_type: 'mysfdomain-ui',
		codebase: {
			version: meta.version || null,
			branch: meta.branch || null,
		},
	};
}
export function usePeachy() {
	if (typeof window === 'undefined') {
		return; // ssr escape
	}
	const host = import.meta.env.VUE_APP_LOCAL_PEACHY_HOST || `peachy.prod.mirus.io/record`;
	const client = new Keen({
		host,
		projectId: 'foo',
		writeKey: 'bar',
		protocol: import.meta.env.VUE_APP_LOCAL_PEACHY_HOST ? 'http' : `https`,
		requestType: 'xhr', // makes testing a lot easier
	});

	client.extendEvents(extender);
	// client.initAutoTracking({
	// 	ignoreDisabledFormFields: false,
	// 	ignoreFormFieldTypes: ['password'],
	// 	recordClicks: true,
	// 	recordFormSubmits: true,
	// 	recordPageViews: true,
	// 	recordScrollState: true,
	// });

	return client.recordEvent.bind(client);
}
