<template>
	<a
		v-if="Boolean($props.href)"
		:href="$props.href || $props.to"
		target="_blank"
		rel="nofollow noreferrer noopener"
		:class="[inheritColor ? 'inheritColor' : colorClass]"
		v-bind="$attrs"
	>
		<slot />

		<span class="sr-only">Opens a new window</span>
	</a>

	<RouterLink
		v-else
		v-slot="{ isActive, isExactActive, href: hrefFromRouterLink, navigate }"
		v-bind="$props"
		custom
	>
		<!--		:disabled="isExactActive"-->
		<a
			:aria-current="isActive ? 'page' : null"
			:class="[
				{
					// [$props.exactActiveClass]: isExactActive,
					[$props.activeClass]: isActive,
				},
				inheritColor ? 'inheritColor' : colorClass,
			]"
			target="_self"
			:href="hrefFromRouterLink"
			v-bind="$attrs"
			@click="navigate"
			@keydown.esc="drawerIsOpen = false"
		>
			<slot
				v-bind="{
					isActive,
					// isExactActive
				}"
			/>
		</a>
	</RouterLink>
</template>

<script setup>
/*
  Tweaked example from vue-router docs:
  https://router.vuejs.org/guide/advanced/extending-router-link.html
*/
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import { getColorClass } from '@/utils/styles';

defineOptions({
	inheritAttrs: false,
});

const props = defineProps({
	...RouterLink.props,

	to: {
		type: Object,
		default: null,
		required: false,
		validator(value) {
			if (typeof value === 'string') {
				console.warn(
					`The "to" prop received the string ${value}, which should be passed to the href prop instead.`
				);
				return false;
			}
			return true;
		},
	},
	href: { type: String, default: null },

	color: { type: String, default: null },
	inheritColor: { type: Boolean, default: false },
});

const colorClass = computed(() =>
	props.inheritColor ? '' : getColorClass(props.color, 'mirusOrange')
);
</script>
