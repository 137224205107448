<template>
	<div
		role="alert"
		class="fixed left-0 top-4 z-50 w-full transition-all"
		:class="{
			'translate-y-0 opacity-100': showSnackbar,
			'-translate-y-24 opacity-0 pointer-events-none': !showSnackbar,
		}"
		:aria-hidden="!showSnackbar"
	>
		<div class="container mx-auto w-2/5 drop-shadow-lg">
			<slot />
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
	timeout: { type: Number, default: -1 },
	display: { type: Boolean, default: true },
});

var timeoutId = ref(null);
const timeoutReached = ref(false);
const showSnackbar = computed(() => props.display && !timeoutReached.value);

watch(
	() => props.display,
	() => {
		if (timeoutId.value) {
			clearTimeout(timeoutId.value);
		}

		timeoutReached.value = false;
		if (props.timeout > 0) {
			timeoutId.value = setTimeout(() => (timeoutReached.value = true), props.timeout);
		}
	}
);
</script>

<style scoped></style>
