import { ref } from 'vue';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';
import { useRoute } from 'vue-router';

export default defineStore('agent/billing', () => {
	const VueRoute = useRoute();

	const receiptList = ref([]);
	const creditsList = ref([]);
	const billingData = ref(null);

	async function getReceiptList() {
		try {
			const { data } = await useApi('receipt/', {
				agent: true,
				agentID: VueRoute.params.agentID,
				message: 'There was an issue retrieving your billing info! Please try again later.',
			}).json();

			if (data?.value?.objects?.length > 0) {
				receiptList.value = data.value.objects;
			}
		} catch (error) {
			console.error(error);
			return Promise.resolve();
		}

		return Promise.resolve();
	}

	async function getBillingAmount(amount) {
		const { data } = await useApi(`billing/calc/?amount=${amount}`, {
			agent: true,
			agentID: VueRoute.params.agentID,
			message: 'There was an issue retrieving your billing info! Please try again later.',
		}).json();
		billingData.value = data.value;
	}

	async function getCreditsList() {
		try {
			const { data } = await useApi(
				`billing/credits/`,

				{
					agent: true,
					agentID: VueRoute.params.agentID,
					message:
						"Uh oh, we couldn't get your billing history from the server! Please try again later.",
				}
			).json();
			creditsList.value = data?.value?.objects;

			return Promise.resolve();
		} catch (error) {
			console.error(error);
			return Promise.reject();
		}
	}

	return {
		receiptList,
		creditsList,
		billingData,

		getReceiptList,
		getBillingAmount,
		getCreditsList,
	};
});
