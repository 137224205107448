export function getRoundedClass(rounded) {
	switch (rounded) {
		case 'top':
			return 'rounded-t';
		case 'right':
			return 'rounded-r';
		case 'bottom':
			return 'rounded-b';
		case 'left':
			return 'rounded-l';
		case 'full':
			return 'rounded-full';

		case 'xs':
		case 'sm':
		case 'md':
		case 'lg':
		case 'xl':
			return `rounded-${rounded}`;

		case false:
		case 'false':
			return 'rounded-none';

		case true:
		case 'true':
		default:
			return 'rounded';
	}
}

export function getColorClass(color, fallback = 'mirusOrange') {
	switch (color) {
		case 'blue':
		case 'info':
			return 'infoBlue';

		case 'yellow':
		case 'warn':
			return 'warnYellow';

		case 'green':
		case 'success':
			return 'successGreen';

		case 'red':
		case 'error':
			return 'errorRed';

		case 'gray':
		case 'secondary':
			return 'mirusGray';

		case 'darkGray':
		case 'dark-gray':
			return 'darkGray';

		case 'white':
			return 'mirusWhite';

		case 'orange':
		case 'primary':
			return 'mirusOrange';

		case 'inherit':
			return 'inherit';

		default:
			return fallback;
	}
}
export function getTextAlignmentClass(alignment, fallback = 'center') {
	switch (alignment) {
		case 'left':
			return 'text-left';
		case 'right':
			return 'text-right';

		case 'center':
			return 'text-center';
		default:
			return fallback;
	}
}
