<template>
	<div class="h-full overflow-auto">
		<div class="rounded-md bg-white p-2">
			<p class="text-center text-gray-600">
				{{ daysUntilBilling }} days till next billing day,
				{{ format(nextBillingDay, 'MMMM d, yyyy') }}
			</p>

			<h3 class="ml-3 text-2xl text-gray-600">Add custom Credit or Charge</h3>

			<form class="grid grid-cols-4 gap-x-5 pl-3" @submit.prevent="addTransaction">
				<BaseButtonGroup
					v-model="mode"
					:buttons="[
						{ value: 'charge', text: 'Charge' },
						{ value: 'credit', text: 'Credit' },
					]"
					class="my-2"
				/>

				<TextFieldInput
					ref="amountRef"
					v-model="amount"
					label="Amount"
					type="number"
					step="0.01"
					placeholder="amount"
					class="col-span-2 col-start-1"
				/>

				<BaseSelect
					id="billingType"
					v-model="type"
					label="Type"
					:items="typeOptions"
					outlined
					show-label
					class="col-span-2"
				/>

				<BaseTextArea
					v-model="notes"
					placeholder="Notes"
					dense
					label="Notes"
					:rows="1"
					:rows-max="4"
					light
					class="col-span-4"
				/>

				<BaseButton
					color="primary"
					type="submit"
					:disabled="amount <= 0 || type === 'default'"
					class="col-start-4"
				>
					Add
				</BaseButton>

				<BaseAlert v-if="status" :type="transactionFailed ? 'error' : 'success'">
					<p>{{ status }}</p>

					<p v-if="transactionID">
						Braintree page for transaction
						<a
							href="`//www.braintreegateway.com/merchants/3g737q2bszkrq2v7/transactions/${transaction_id}`"
						>
							{{ transactionID }}
						</a>
					</p>
				</BaseAlert>
			</form>
		</div>

		<div class="sticky top-0 mt-4 max-h-full overflow-auto rounded-md bg-white">
			<ProgressBar v-if="loading" text="Loading..." />
			<template v-else-if="credits">
				<h3 class="sticky top-0 bg-white p-2 text-2xl text-gray-600 shadow">
					Past Credits
				</h3>
				<ul class="m-4 flex flex-col gap-2">
					<li
						v-for="credit in credits"
						:key="credit.category + credit.created"
						class="rounded-md border-2 border-gray-400 p-2"
					>
						<div>
							<span class="font-bold">Credit Type: </span>
							<q>
								{{
									[...creditCategories, ...oneoffCategories].find(
										cat => cat.type === credit.category
									).title
								}}
							</q>
						</div>
						<div>
							<span class="font-bold">Used/Amount:</span>
							<span>
								${{ credit.used }}/ ${{ credit.amount }}, Created On
								{{ format(new Date(credit.created), 'yyy/MM/dd') }}
							</span>
						</div>
						<div v-if="credit.notes">
							<span class="font-bold">Notes</span>
							<blockquote class="border-l-2 border-gray-500 pl-2">
								{{ credit.notes }}
							</blockquote>
						</div>
					</li>
				</ul>
			</template>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
	addMonths,
	differenceInCalendarDays,
	format,
	isAfter,
	setDate,
	startOfDay,
} from 'date-fns';

import { capitalize } from '@/utils';

import useAgentStore from '@/stores/agent/agent';
import { useApi } from '@/composables/useApi';

import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseTextArea from '@/components/ui/BaseTextArea.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseButtonGroup from '@/components/ui/BaseButtonGroup.vue';

const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);
const VueRoute = useRoute();

const creditCategories = [
	{ type: 'overcharge', title: 'Refund for Overcharge' },
	{ type: 'change', title: 'Refund for Service Change' },
	{ type: 'change2', title: 'Refund for Service Change' },
	{ type: 'error', title: 'Credit for Billing Error' },
	{ type: 'goodwill', title: 'Goodwill' },
	{ type: 'promo', title: 'Promotional Credit' },
	{ type: 'pluto', title: 'Upgrade to Monthly Billing' },
	{ type: 'monthly', title: 'Upgrade to Monthly Billing' },
	{ type: 'monthly2', title: 'Upgrade to Monthly Billing' },
	{ type: 'yearly', title: 'Yearly Payment Credit' },
	{ type: 'other', title: 'Other' },
];

const excludeAtCreditCreation = ['pluto', 'monthly2', 'change2'];

const oneoffCategories = [
	{ type: 'Additional Adwords Charge', title: 'Additional Adwords Charge' },
	{ type: 'Initial Adwords Payment', title: 'Initial Adwords Payment' },
	{ type: 'Google Ads Setup Fee', title: 'Google Ads Setup Fee' },
	{ type: 'Yearly Payment', title: 'Yearly Payment' },
	{ type: 'Google Trial Fee', title: 'Google Trial Fee' },
	{ type: 'Domain Change Fee', title: 'Domain Change Fee' },
];

const loading = ref(false);
const reset = ref(0);
const status = ref(null);
const mode = ref('charge');
const type = ref(null);
const notes = ref(null);
const amount = ref(null);
const transactionFailed = ref(null);
const transactionID = ref(null);

const credits = ref(null);
const agentID = ref(null);
// api calls
async function getBillingData() {
	const result = await useApi('billing/credits/', {
		agent: true,
		agentID: VueRoute.params.agentID,
		message: 'There was an issue retrieving your billing data. Please try again later.',
	}).json();

	credits.value = result?.data?.value?.objects;
}
async function addBillingAlteration({ amount, category, notes, mode }) {
	const result = await useApi('billing/alterations/', {
		agent: true,
		agentID: agentID.value,
		message: 'There was an issue adding your billing changes. Please try again later.',
	})
		.post({
			amount,
			category,
			notes,
			mode,
		})
		.json();
	return Promise.resolve({ data: result.data?.value });
}
// end api calls

const typeOptions = computed(() => [
	...(mode.value === 'credit' ? creditCategories : oneoffCategories)
		.filter(({ type }) => {
			return !excludeAtCreditCreation.includes(type);
		})
		.map(({ title, type }) => {
			return { text: title, value: type };
		}),
]);
async function addTransaction() {
	status.value = null;
	if (
		mode.value === 'charge' &&
		type.value === 'Initial Adwords Payment' &&
		Number(amount.value) <= 40
	) {
		transactionFailed.value = true;

		status.value = 'Initial Adwords Payment must be greater than $40';
		return;
	}
	loading.value = true;
	transactionFailed.value = null;
	transactionID.value = null;

	try {
		const { data } = await addBillingAlteration({
			amount: amount.value,
			category: type.value,
			notes: notes.value,
			mode: mode.value,
		});

		reset.value += 1;
		status.value = null;
		amount.value = null;
		notes.value = null;
		type.value = null;

		loading.value = false;

		if (data.success) {
			status.value =
				mode.value === 'credit'
					? 'Credit creation complete'
					: 'Charge created successfully';
		} else {
			status.value = `${capitalize(mode.value)} creation failed, please try again later.`;
		}

		transactionFailed.value = !data.success;
		transactionID.value = data.transaction_id;
		await fetchData();
	} catch (error) {
		status.value = null;
		console.error(error);
	}
}

const nextBillingDay = new Date();
setDate(nextBillingDay, agent.value?.billing_day);
const shouldAddMonth = isAfter(new Date(), nextBillingDay);
if (shouldAddMonth) {
	addMonths(nextBillingDay, 1);
}
const daysUntilBilling = differenceInCalendarDays(nextBillingDay, startOfDay(new Date()));
async function fetchData() {
	loading.value = true;
	await getBillingData();
	loading.value = false;
}
onMounted(() => {
	agentID.value = useRoute().params.agentID;
	fetchData();
});
watch(
	() => agent.value,
	() => {
		if (agent?.value) {
			fetchData();
		}
	}
);
watch(mode, () => {
	amount.value = null;
	notes.value = null;
	type.value = 'default';
});
</script>
