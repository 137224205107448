import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { formatISO, isDate } from 'date-fns';

import { useApi } from '@/composables/useApi';
import useAlertStore from '@/stores/alerts';

export default defineStore('agent/qlp', () => {
	const VueRoute = useRoute();

	const views = ref(null);
	const stats = ref([]);
	const preference = ref(null);
	const isLoading = ref(false);
	const activeDomains = ref({});

	async function getStats(start, end) {
		if (!isDate(start) || !isDate(end)) {
			throw 'Valid start and end dates required';
		}

		const { data } = await useApi(
			`campaigns/?${new URLSearchParams({
				start: formatISO(start).substring(0, 19),
				end: formatISO(end).substring(0, 19),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your QLP stats from the server! Please try again later.',
			}
		).json();
		stats.value = data.value;
	}

	async function getViews(start, end) {
		if (!isDate(start) || !isDate(end)) {
			throw 'Valid start and end dates required';
		}

		const { data } = await useApi(
			`campaigns/qlp_views/?${new URLSearchParams({
				start: formatISO(start).substring(0, 19),
				end: formatISO(end).substring(0, 19),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your QLP stats from the server! Please try again later.',
			}
		).json();
		views.value = data.value?.qlp_views;
	}

	async function getPreferences() {
		try {
			const { data } = await useApi('qlp/', {
				agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your QLP preferences from the server! Please try again later.',
			}).json();
			preference.value = data.value?.qlp_preference;
			activeDomains.value = data.value?.active_domains;
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function changePreference(domainName, preference) {
		try {
			await useApi(`domains/${domainName}/change_single_qlp/`, {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue changing your qlp preferences. Please try again later.',
			}).post({ preference });

			await getPreferences();
		} catch (error) {
			const alertStore = useAlertStore();

			alertStore.addAlert({
				message:
					"Uh oh, we couldn't get your stuff from the server! Please try again later",
			});
			return Promise.reject(error);
		}
	}

	return {
		views,
		stats,
		preference,
		isLoading,
		activeDomains,

		getStats,
		getViews,
		getPreferences,
		changePreference,
	};
});
