import { createRouter, createWebHistory } from 'vue-router';
import { storeToRefs } from 'pinia';

import AgentRoutes from '@/router/AgentRoutes';
import EditorRoutes from '@/router/EditorRoutes';
import OverviewRoutes from '@/router/OverviewRoutes';

import useUserStore from '@/stores/user';
import useNavigationStore from '@/stores/navigation';

import { prepareRoutes, handleAgentEnter } from '@/router/utils';

const baseRoutes = [
	{
		path: '/',
		name: 'index',
		meta: { auth: true },

		redirect: () => {
			const userStore = useUserStore();
			const { user } = storeToRefs(userStore);
			if (user.value?.user_type) {
				switch (user.value?.user_type) {
					case 'mirus':
						return {
							name: 'mirus',
							params: { userID: user.value.user?.replace(/@[\d\D]*/, '') },
						};

					case 'staff':
						return { name: 'atms', params: { staffID: user.value.user_associate_id } };
					case 'corp':
						return {
							name: 'employees',
							params: { employeeID: user.value.user_associate_id },
						};
					case 'agent':
						return {
							name: 'dashboard',
							params: { agentID: user.value.user_associate_id },
						};

					case 'unknown':
						return { name: 'user', params: { userID: user.value.user_details.email } };

					default:
						break;
				}
			}

			return `/login`;
		},
	},
	{
		path: '/user/:userID/',
		name: 'user',
		component: () => import('@/views/Landing/UserLanding'),
		meta: {
			auth: true,
		},
	},
	{
		path: '/mirus/:userID/',
		name: 'mirus',
		component: () => import('@/views/Landing/MirusLanding'),
		meta: {
			auth: true,
		},
	},
	{
		path: '/atms/:staffID/',
		name: 'atms',
		components: { default: () => import('@/views/Landing/StaffLanding') },
		meta: {
			auth: true,
		},
	},
	{
		path: '/employees/:employeeID/',
		name: 'employees',
		components: { default: () => import('@/views/Landing/CorporateLanding') },
		meta: {
			auth: true,
		},
	},

	{
		path: '/agents/:agentID/',
		beforeEnter: handleAgentEnter,

		meta: {
			auth: true,
			navigation: true,
		},
		children: [
			{
				path: '/agents/:agentID/',
				components: {
					default: () => import('@/views/Agents/AgentViews'),
					navigation: () => import('@/components/navigation/AgentNavigation'),
				},
				children: AgentRoutes,
			},

			{
				path: '/agents/:agentID/domains/:domainName/',
				children: EditorRoutes,
				components: {
					default: () => import('@/views/MXEditor/MXEditor'),
					navigation: () => import('@/components/navigation/EditorNavigation'),
				},
				meta: {
					title: 'Edit Domain',
					auth: true,
					navigation: true,
				},
			},
		],
	},

	{ path: '/login/', name: 'login', component: () => import('@/views/Authentication/TheLogin') },
	{
		path: '/logout/',
		name: 'logout',
		component: () => import('@/views/Authentication/TheLogout'),
	},
	{
		path: '/forbidden/',
		name: 'forbidden',
		component: () => import('@/views/Authentication/AccessDenied'),
	},
	{ path: '/m1-video/', name: 'm1-video', component: () => import('@/views/M1Video') },
	{ path: '/m2-video/', name: 'm2-video', component: () => import('@/views/M2Video') },
	{
		path: '/overview/',
		name: 'overview',

		components: {
			default: () => import('@/views/Overview/OverviewViews'),
			navigation: () => import('@/components/navigation/OverviewNavigation'),
		},
		children: OverviewRoutes,
		meta: {
			auth: true,
			navigation: true,
		},
	},
	// todo: confirm this was only used for M1-UI
	// {
	// 	path: '/m1auth/*',
	// 	meta: {
	// 		auth: true,
	// 	},
	// 	beforeEnter(to) {
	// 		window.location.href = `http://mysfdomain.com${to.path}`;
	// 	},
	// },
	{
		path: '/glossary/',
		name: 'glossary',
		meta: {
			auth: true,
		},
		component: () => import('@/views/GlossaryList'),
	},

	{ path: '/*', component: () => import('@/views/FourOhFour') },
];

export const routes = prepareRoutes(baseRoutes);
const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: 'linkActive',
	linkExactActiveClass: 'linkExactActive',

	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { el: '#main' };
		}
	},
	routes: baseRoutes,
});

router.beforeEach(async (to, from, next) => {
	const navigationStore = useNavigationStore();

	navigationStore.showNavigation = to.matched.some(route => route.meta.navigation);

	const userStore = useUserStore();

	// update page title based on route
	if (to.meta?.title) {
		switch (typeof to.meta?.title) {
			case 'string':
				document.title = to.meta.title;
				break;
			case 'function':
				document.title = to.meta.title(to);
				break;
			default:
				document.title = 'MySFdomain';
		}
	}

	// check whether any level of the route is for Mirus users only
	const mirusOnly = to.matched.some(route => route.meta.mirusOnly);

	if (mirusOnly && !userStore.isMirusUser) {
		localStorage.setItem('accessDeniedPath', to.path);
		//todo: update this to a return statement with vue router 4.x+
		next({ name: 'forbidden' });
	}

	// check whether any level of the route requres authentication && make sure user is authenticated if needed
	const authRequired = to.matched.some(route => route.meta.auth);

	if (authRequired && userStore.user === null) {
		await userStore.getUserData();
	}

	if (authRequired && userStore.user?.authenticated !== true) {
		localStorage.setItem('nextPath', to.path);
		//todo: update this to a return statement with vue router 4.x+
		next({ name: 'login' });
	}

	// if we made it this far it's safe to continue
	// this won't be necessary with vue router 4.x+,
	// as returning nothing will continue to the route
	next();
});

export default router;
