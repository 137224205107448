<template>
	<section>
		<BaseAutocomplete
			ref="autocomplete"
			autofocus
			no-filter
			label="Agent Search"
			placeholder="Search for an Agent"
			clearable
			return-object
			:loading="loading"
			:items="results || []"
			@update:search-input="onSearchText"
			@change="chooseItem"
			@click:clear="clearResults"
		>
			<template v-if="empty" #no-data>
				<li class="p-2 font-medium">No matching agents</li>
			</template>
			<template #item="{ associate_id, title, subtext }">
				<div class="flex items-center">
					<SFProfileImage :associate_id="associate_id" class="mr-4 grow-0" width="40" />

					<div class="flex flex-col items-start lg:flex-row lg:flex-wrap">
						<span class="mr-2 font-medium uppercase">
							{{ title }}
						</span>
						<span class="text-sm"> ({{ subtext }}) </span>
					</div>
				</div>
			</template>
		</BaseAutocomplete>
		<template v-if="showHistory && history?.length > 0">
			<h3 class="mb-1 mt-4 text-xl font-semibold">Recently Viewed Agents</h3>

			<ul class="flex flex-col gap-2">
				<li v-for="item in history" :key="item.link">
					<BaseButton
						outline
						rounded="xl"
						color="darkGray"
						class="w-full !p-2 !shadow-none"
						v-bind="shouldEmit ? {} : { to: { path: item.link } }"
						@click="shouldEmit ? $emit('choose', item) : null"
					>
						<span class="flex w-full items-center justify-start gap-3">
							<SFProfileImage width="48" :associate_id="item.associate_id" />
							<span class="text-left">
								<span class="text-xl font-semibold">
									{{ item.title }}
								</span>
								<span class="text-xs opacity-60">
									{{ item.subtext }}
								</span>
							</span>
						</span>
					</BaseButton>
				</li>
			</ul>
		</template>
	</section>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';

import SFProfileImage from '@/components/common/SFProfileImage';
import { useApi } from '@/composables/useApi';
import { useRouter } from 'vue-router';
import { useLocalStorage } from '@vueuse/core';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAutocomplete from '@/components/ui/BaseAutocomplete.vue';
const emit = defineEmits(['choose']);
const props = defineProps({
	dark: { type: Boolean, default: false },
	shouldEmit: { type: Boolean, default: false },
	showHistory: { type: Boolean, default: true },
});

const router = useRouter();

const historyStorage = useLocalStorage('search_history', '[]');

const autocomplete = ref(null);
const search = ref('');
const results = ref(null);
const timeoutID = ref(null);

const loading = computed(() => Boolean(!results.value && search.value));
const empty = computed(() => results.value && results.value.length === 0);
const history = computed(() => JSON.parse(historyStorage.value));

function clearResults() {
	results.value = null;
	search.value = '';
	if (timeoutID.value) {
		clearTimeout(timeoutID.value);
	}
}

function onSearchText(val) {
	if (search.value === val) {
		return;
	}
	const value = val ? val.toLowerCase() : '';
	const t = encodeURIComponent(value);

	if (timeoutID.value) {
		clearTimeout(timeoutID.value);
	}

	results.value = null;
	search.value = value;

	if (search.value) {
		timeoutID.value = setTimeout(async () => {
			const { data } = await useApi(`api/agents/user/search/?q=${t}`, {
				message: 'There was an issue searching for your request. Please try again later.',
			}).json();
			// if the user has cleared the search box after the request was sent,
			// don't display the results.
			if (search.value) {
				results.value = data.value.results;
			}
		}, 500);
	}
}

async function chooseItem(chosenItem) {
	if (chosenItem === null || chosenItem === undefined) {
		return;
	}
	const matched = history.value.some(existing => {
		return existing.link === chosenItem?.link;
	});
	if (!matched) {
		let newHistory = [...history.value];
		newHistory.unshift(chosenItem);
		newHistory = newHistory.slice(0, 10);

		historyStorage.value = JSON.stringify(newHistory);
	}
	results.value = null;
	if (props.shouldEmit) {
		emit('choose', chosenItem);
	} else {
		await nextTick(); // allow DOM update to complete. Without this, history is not always successfully saved within Mirus controls
		router.push(chosenItem.link);
	}
}

defineExpose({
	autocomplete,
});
</script>

<style scoped></style>
