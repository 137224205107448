<template>
	<div class="flex grow-0 items-center justify-center gap-2">
		<span class="whitespace-nowrap">Rows per page:</span>
		<BaseSelect
			:id="`table-paginator-${title}`"
			:items="rowsPerPageOptions"
			:show-default-option="false"
			:model-value="rowsPerPage"
			class="grow-0 !-mb-4"
			label="Rows per page"
			@update:model-value="handleRowCountChange"
		/>

		<button v-show="itemCount > rowsPerPage" type="button" @click="previousPage">
			<FAIcon icon="chevron-left" />
		</button>
		<span class="whitespace-nowrap text-sm text-gray-900 dark:text-white">
			<span class="hidden md:inline"> Showing items </span>
			{{ startIndex + 1 }}-{{ Math.min(endIndex, itemCount) }}
			<span>of</span>
			{{ itemCount }}
		</span>
		<button v-show="itemCount > rowsPerPage" type="button" @click="nextPage">
			<FAIcon icon="chevron-right" />
		</button>
	</div>
</template>
<script setup>
import { ref, computed, watchEffect, watch } from 'vue';

import { findClosestNumberInArray } from '@/utils';

import BaseSelect from '@/components/ui/BaseSelect.vue';

const emit = defineEmits(['update:rows-per-page', 'update:start-index', 'update:end-index']);

const props = defineProps({
	rowsPerPage: { type: Number, required: true },
	itemCount: { type: Number, required: true },
	startIndex: { type: Number, required: true },
	endIndex: { type: Number, required: true },

	title: { type: String, required: true },
});
const VALID_ROWS_PER_PAGE = [10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200, 250];

function computeOptions(itemCount) {
	const options = [{ value: 5, text: 5 }];

	VALID_ROWS_PER_PAGE.forEach(number => {
		if (number > itemCount) {
			return;
		}
		options.push({ value: number, text: number });
	});

	options.push({ text: 'All', value: itemCount });

	return options;
}

const currentPage = ref(1);

const totalPages = ref(Math.ceil(props.itemCount / props.rowsPerPage));

const rowsPerPageOptions = computed(() => computeOptions(props.itemCount));
function nextPage() {
	if (currentPage.value < totalPages.value) {
		currentPage.value += 1;
	} else {
		currentPage.value = 1;
	}
}
function previousPage() {
	if (currentPage.value > 1) {
		currentPage.value -= 1;
	} else {
		currentPage.value = totalPages.value;
	}
}

function handleRowCountChange(newValue) {
	if (newValue && newValue !== props.rowsPerPage) {
		emit('update:rows-per-page', newValue);
	}
}

watchEffect(() => {
	totalPages.value = Math.ceil(props.itemCount / props.rowsPerPage);

	if (currentPage.value > totalPages.value) {
		currentPage.value = totalPages.value;
	}

	if (currentPage.value < 1) {
		currentPage.value = 1;
	}

	const newStart = (currentPage.value - 1) * props.rowsPerPage;
	emit('update:start-index', newStart);

	emit('update:end-index', newStart + Math.min(props.rowsPerPage, props.itemCount));
});
watch(
	[() => props.rowsPerPage, () => props.itemCount],
	([newRowsPerPage, newItemCount], [previousRowsPerPage, previousItemCount]) => {
		if (
			// rows per page was set to 'All'
			newRowsPerPage === previousRowsPerPage &&
			previousItemCount === previousRowsPerPage
		) {
			emit('update:rows-per-page', newItemCount);
		} else if (newRowsPerPage < 5) {
			// Limit rows-per-page to a minimum of 5

			emit('update:rows-per-page', 5);
		} else if (newRowsPerPage !== newItemCount || newRowsPerPage > newItemCount / 2) {
			//  limit the rows per page to either:

			//  1. half the total item count
			const halfwayPoint = Math.ceil(newItemCount / newRowsPerPage / 2) * newRowsPerPage;

			// 2. the new amount, rounded down by 5s,
			const roundedTarget = findClosestNumberInArray(
				[5, ...VALID_ROWS_PER_PAGE],
				newRowsPerPage
			);

			const newAmount = Math.min(roundedTarget, halfwayPoint);

			if (newAmount !== previousRowsPerPage) {
				emit('update:rows-per-page', newAmount);
			}
		}
	}
);
defineExpose({
	currentPage,
	totalPages,
	rowsPerPageOptions,

	nextPage,
	previousPage,
});
</script>
