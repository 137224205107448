<template>
	<BaseAlert
		v-if="alert"
		:rounded="false"
		:type="alert.type"
		class="m-0 drop-shadow-lg"
		:class="alert.type"
	>
		<p>
			<span class="pr-1">({{ format(alert.timestamp, 'h:mm:ss a') }})</span>
			<span>{{ alert.message }}</span>
		</p>
		<p>
			Please
			<AppLink :color="alert.type" href="mailto:support@mysfdomain.com">
				contact support</AppLink
			>&nbsp;if you've seen this message multiple times.
		</p>

		<template #action>
			<div v-if="alerts.length > 1" class="flex shrink gap-2 self-center">
				<BaseButton color="inherit" text title="Previous" @click="showPreviousAlert">
					<FAIcon icon="chevron-left" />
				</BaseButton>
				<small class="self-center">
					Error {{ currentAlertIndex + 1 }} / {{ alerts.length }}
				</small>
				<BaseButton color="inherit" text title="Next" @click="showNextAlert">
					<FAIcon icon="chevron-right" />
				</BaseButton>
			</div>

			<BaseButton
				class="self-center !outline-none"
				outline
				rounded="lg"
				text
				@click="dismissAlert(alert.timestamp)"
			>
				Dismiss
			</BaseButton>
		</template>
	</BaseAlert>
</template>

<script setup>
import { format } from 'date-fns';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import useAlertStore from '@/stores/alerts';

import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import AppLink from '@/components/ui/AppLink.vue';

const alertStore = useAlertStore();
const { alerts } = storeToRefs(alertStore);

const currentAlertIndex = ref(0);
const alert = computed(() => alertStore.alerts?.[currentAlertIndex.value]);

function dismissAlert(id) {
	alertStore.dismissAlert(id);
	currentAlertIndex.value = Math.max(
		Math.min(currentAlertIndex.value, alerts.value.length - 1),
		0
	);
}

function showNextAlert() {
	currentAlertIndex.value = (currentAlertIndex.value + 1) % alerts.value.length;
}

function showPreviousAlert() {
	const tempTarget = currentAlertIndex.value - 1;
	currentAlertIndex.value = tempTarget >= 0 ? tempTarget : alerts.value.length - 1;
}
</script>
