import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { formatISO, isDate } from 'date-fns';

import { useApi } from '@/composables/useApi';
import useAlertStore from '@/stores/alerts';

export default defineStore('agent/adwords', () => {
	const VueRoute = useRoute();

	const adwordStats = ref(null);
	const awacAccount = ref(null);
	const adwordsIsLoading = ref(false);
	const accountStatus = ref(null);

	async function getAdwordStats(start, end) {
		if (!isDate(start) || !isDate(end)) {
			return Promise.reject('Valid start and end dates required');
		}

		adwordStats.value = null;
		const { data } = await useApi(
			`awac/?${new URLSearchParams({
				start: formatISO(start).substring(0, 19),
				end: formatISO(end).substring(0, 19),
			})}`,
			{
				agent: true,
				agentID: VueRoute.params.agentID,
				message: 'There was an issue retrieving your adwords data! Please try again later.',
			}
		).json();
		const statsList = data?.value?.objects;
		adwordStats.value = statsList[0];
		return Promise.resolve();
	}

	async function getAwacAccount() {
		awacAccount.value = null;

		const { statusCode, data } = await useApi('awac/account/', {
			agent: true,
			agentID: VueRoute.params.agentID,
			message: `We couldn't retrieve your adwords account data! Please try again later.`,
		}).json();

		awacAccount.value = data?.value;

		// NOTE: adblockers cause successful responses with no data from the api
		if (statusCode.value === 200 && [null, undefined, ''].includes(data.value)) {
			useAlertStore().addAlert({
				message:
					"We received an odd response from the server. Please confirm that any adblockers are disabled, as they can interfere with requests containing the keyword 'adwordStats'.",
			});
		}
	}

	async function getAccountStatus() {
		const { data } = await useApi('awac/account_status/', {
			agent: true,
			agentID: VueRoute.params.agentID,
			message: `We couldn't retrieve your adwords account data`,
		}).json();

		accountStatus.value = data?.value;
	}

	return {
		adwordStats,
		awacAccount,
		adwordsIsLoading,
		accountStatus,

		getAdwordStats,
		getAwacAccount,
		getAccountStatus,
	};
});
